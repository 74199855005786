import { Autocomplete, Box, Grid, LinearProgress, TextField, Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ReasonForm(props) {
  const {
    isDisabled,
    modalForm,
    actionReasons,
    isLoadingActionReasons, } = props;
  const [selectedReason, setSelectedReason] = useState(null);
  const [comment, setComment] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    modalForm.current = {};
  }, []);

  useEffect(() => {
    const newForm = {}
    if (selectedReason?.name) {
      newForm['reason'] = selectedReason.name;
    }
    modalForm.current = {
      comment,
      ...newForm,
    }
  }, [selectedReason, comment]);

  return (
    <Box sx={{ p: 1, mt: 3 }}>
      <Grid container sx={{ alignItems: 'center' }} rowSpacing={3}>
        {isLoadingActionReasons ?
          <Box sx={{ width: '100%', minHeight: '10em' }}>
            <Skeleton variant="text" width="100%" height='7em' />
            <Skeleton variant="rectangular" width="100%" height="7em" />
          </Box> :
          <>
            <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
              <Autocomplete
                id="controllable-selecter"
                required
                fullWidth
                disableClearable
                value={selectedReason}
                disabled={isLoadingActionReasons || isDisabled}
                options={actionReasons ? actionReasons : []}
                getOptionLabel={(option) => option ? option?.name : ''}
                onChange={(_, newValue) => { setSelectedReason(newValue); }}
                isOptionEqualToValue={(option, value) => option?.name === value?.name}
                renderInput={(params) => <TextField {...params} label={t('overseer_app.widget.select_reason', 'Select a reason for this action')}
                  required
                />}
              />
              {isLoadingActionReasons && <LinearProgress size={20} />}
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-textarea"
                label="Comment (optional)"
                placeholder="Add a comment"
                disabled={isDisabled}
                multiline
                value={comment}
                onChange={(event) => { setComment(event.target.value); }}
                sx={{ width: '100%', minHeight: '5em' }}
              />
            </Grid>
          </>
        }
      </Grid>
    </Box>
  );
}
