import { connect } from 'react-redux';

import {
  getNewSessionResponse,
  getReasonOptions,
  isLoadingActionReasons,
  loadingNewSessionResponse,
} from 'core/selectors';

import { postNewSessions, removeNewSessionResponse, updateNewSessions } from 'core/slices/baldur/scheduler';
import { getActionReasons } from 'core/slices/robotMonitoring';
import { setSnackAlert } from 'core/slices/snackAlert';

import SessionForm from '../../../components/fleetManagement/forms/SessionForm';

export default connect(
  (state) => ({
    actionReasons: getReasonOptions(state),
    isLoadingActionReasons: isLoadingActionReasons(state),
    newSessionResponse: getNewSessionResponse(state),
    loadingNewSessions: loadingNewSessionResponse(state),
  }),
  {
    getActionReasons,
    postNewSessions,
    updateNewSessions,
    removeNewSessionResponse,
    setSnackAlert,
  }
)(SessionForm);
