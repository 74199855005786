export const CREDENTIALS_REQUEST = 'CREDENTIALS_REQUEST';
export const CREDENTIALS_AVAILABLE = 'CREDENTIALS_AVAILABLE';
export const CREDENTIALS_UNAVAILABLE = 'CREDENTIALS_UNAVAILABLE';
export const CREDENTIALS_STORE = 'CREDENTIALS_STORE';
export const CREDENTIALS_CLEAR = 'CREDENTIALS_CLEAR';

export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const LOG_OUT_REQUEST = 'LOG_OUT_REQUEST';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_FAILURE = 'LOG_OUT_FAILURE';

export const SNACK_SET = 'SNACK_SET';

export const USER_INFO_GET_REQUEST = 'USER_INFO_GET_REQUEST';
export const USER_INFO_GET_SUCCESS = 'USER_INFO_GET_SUCCESS';
export const USER_INFO_GET_FAILURE = 'USER_INFO_GET_FAILURE';

// Navigation options
export const OPTIONS_REQUEST = 'OPTIONS_REQUEST';
export const OPTIONS_SUCCESS = 'OPTIONS_SUCCESS';
export const OPTIONS_FAILURE = 'OPTIONS_FAILURE';

// Metrics Cloud Run
export const GET_REVISIONS = 'GET_REVISIONS';
export const GET_REVISIONS_SUCCESS = 'GET_REVISIONS_SUCCESS';
export const GET_REVISIONS_FAILURE = 'GET_REVISIONS_FAILURE';

export const GET_REQUEST_COUNTS = 'GET_REQUEST_COUNTS';
export const GET_REQUEST_COUNTS_SUCCESS = 'GET_REQUEST_COUNTS_SUCCESS';
export const GET_REQUEST_COUNTS_FAILURE = 'GET_REQUEST_COUNTS_FAILURE';

export const GET_CONTAINER_INSTANCES = 'GET_CONTAINER_INSTANCES';
export const GET_CONTAINER_INSTANCES_SUCCESS =
  'GET_CONTAINER_INSTANCES_SUCCESS';
export const GET_CONTAINER_INSTANCES_FAILURE =
  'GET_CONTAINER_INSTANCES_FAILURE';

export const GET_REQUEST_LATENCIES = 'GET_REQUEST_LATENCIES';
export const GET_REQUEST_LATENCIES_SUCCESS = 'GET_REQUEST_LATENCIES_SUCCESS';
export const GET_REQUEST_LATENCIES_FAILURE = 'GET_REQUEST_LATENCIES_FAILURE';

export const GET_CPU_UTILIZATION = 'GET_CPU_UTILIZATION';
export const GET_CPU_UTILIZATION_SUCCESS = 'GET_CPU_UTILIZATION_SUCCESS';
export const GET_CPU_UTILIZATION_FAILURE = 'GET_CPU_UTILIZATION_FAILURE';

export const GET_MEMORY_UTILIZATION = 'GET_MEMORY_UTILIZATION';
export const GET_MEMORY_UTILIZATION_SUCCESS = 'GET_MEMORY_UTILIZATION_SUCCESS';
export const GET_MEMORY_UTILIZATION_FAILURE = 'GET_MEMORY_UTILIZATION_FAILURE';

// Metrics Vertex
export const GET_VERTEX_RESPONSE_COUNT = 'GET_VERTEX_RESPONSE_COUNT';
export const GET_VERTEX_RESPONSE_COUNT_SUCCESS =
  'GET_VERTEX_RESPONSE_COUNT_SUCCESS';
export const GET_VERTEX_RESPONSE_COUNT_FAILURE =
  'GET_VERTEX_RESPONSE_COUNT_FAILURE';

export const GET_VERTEX_PREDICTION_COUNT = 'GET_VERTEX_PREDICTION_COUNT';
export const GET_VERTEX_PREDICTION_COUNT_SUCCESS =
  'GET_VERTEX_PREDICTION_COUNT_SUCCESS';
export const GET_VERTEX_PREDICTION_COUNT_FAILURE =
  'GET_VERTEX_PREDICTION_COUNT_FAILURE';

export const GET_VERTEX_REPLICAS_COUNT = 'GET_VERTEX_REPLICAS_COUNT';
export const GET_VERTEX_REPLICAS_COUNT_SUCCESS =
  'GET_VERTEX_REPLICAS_COUNT_SUCCESS';
export const GET_VERTEX_REPLICAS_COUNT_FAILURE =
  'GET_VERTEX_REPLICAS_COUNT_FAILURE';

export const GET_VERTEX_LATENCIES = 'GET_VERTEX_LATENCIES';
export const GET_VERTEX_LATENCIES_SUCCESS = 'GET_VERTEX_LATENCIES_SUCCESS';
export const GET_VERTEX_LATENCIES_FAILURE = 'GET_VERTEX_LATENCIES_FAILURE';

export const GET_VERTEX_CPU_UTILIZATION = 'GET_VERTEX_CPU_UTILIZATION';
export const GET_VERTEX_CPU_UTILIZATION_SUCCESS =
  'GET_VERTEX_CPU_UTILIZATION_SUCCESS';
export const GET_VERTEX_CPU_UTILIZATION_FAILURE =
  'GET_VERTEX_CPU_UTILIZATION_FAILURE';

export const GET_TASKS_DEPTH = 'GET_TASKS_DEPTH';
export const GET_TASKS_DEPTH_SUCCESS = 'GET_TASKS_DEPTH_SUCCESS';
export const GET_TASKS_DEPTH_FAILURE = 'GET_TASKS_DEPTH_FAILURE';

export const GET_TASKS_COUNT = 'GET_TASKS_COUNT';
export const GET_TASKS_COUNT_SUCCESS = 'GET_TASKS_COUNT_SUCCESS';
export const GET_TASKS_COUNT_FAILURE = 'GET_TASKS_COUNT_FAILURE';

export const GET_SERVICE_METADATA = 'GET_SERVICE_METADATA';
export const GET_SERVICE_METADATA_SUCCESS = 'GET_SERVICE_METADATA_SUCCESS';
export const GET_SERVICE_METADATA_FAILURE = 'GET_SERVICE_METADATA_FAILURE';

export const UPDATE_SERVICE_METADATA = 'UPDATE_SERVICE_METADATA';
export const UPDATE_SERVICE_METADATA_SUCCESS =
  'UPDATE_SERVICE_METADATA_SUCCESS';
export const UPDATE_SERVICE_METADATA_FAILURE =
  'UPDATE_SERVICE_METADATA_FAILURE';

export const UPDATE_SERVICE_TRAFFIC = 'UPDATE_TRAFFIC_METADATA';
export const UPDATE_SERVICE_TRAFFIC_SUCCESS = 'UPDATE_SERVICE_TRAFFIC_SUCCESS';
export const UPDATE_SERVICE_TRAFFIC_FAILURE = 'UPDATE_SERVICE_TRAFFIC_FAILURE';

export const GET_TASKS_METADATA = 'GET_TASKS_METADATA';
export const GET_TASKS_METADATA_SUCCESS = 'GET_TASKS_METADATA_SUCCESS';
export const GET_TASKS_METADATA_FAILURE = 'GET_TASKS_METADATA_FAILURE';

export const GET_ALL_ALERT_POLICIES = 'GET_ALL_ALERT_POLICIES';
export const GET_ALL_ALERT_POLICIES_SUCCESS = 'GET_ALL_ALERT_POLICIES_SUCCESS';
export const GET_ALL_ALERT_POLICIES_FAILURE = 'GET_ALL_ALERT_POLICIES_FAILURE';

// Backend
export const BACKEND_REQUEST = 'BACKEND_REQUEST';
export const BACKEND_AVAILABLE = 'BACKEND_AVAILABLE';
export const BACKEND_UNAVAILABLE = 'BACKEND_UNAVAILABLE';
export const LEGACY_BACKEND_REQUEST = 'LEGACY_BACKEND_REQUEST';
export const LEGACY_BACKEND_AVAILABLE = 'LEGACY_BACKEND_AVAILABLE';
export const LEGACY_BACKEND_UNAVAILABLE = 'LEGACY_BACKEND_UNAVAILABLE';
export const BACKEND_OBJECT_AVAILABLE = 'BACKEND_OBJECT_AVAILABLE';
export const BACKEND_OBJECT_UNAVAILABLE = 'BACKEND_OBJECT_UNAVAILABLE';

// Parameters
export const PARAMETERS_REQUEST = 'PARAMETERS_REQUEST';
export const PARAMETERS_AVAILABLE = 'PARAMETERS_AVAILABLE';
export const PARAMETERS_UNAVAILABLE = 'PARAMETERS_UNAVAILABLE';
export const GET_PARAMETERS = 'GET_PARAMETERS';
export const GET_ALL_PARAMETERS_ROBOTS = 'GET_ALL_PARAMETERS_ROBOTS';
export const GET_VERSIONS_ROBOT = 'GET_VERSIONS_ROBOT';

//Scheduler
export const SESSIONS_REQUEST = 'SESSIONS_REQUEST';
export const SESSIONS_AVAILABLE = 'SESSIONS_AVAILABLE';
export const SESSIONS_UNAVAILABLE = 'SESSIONS_UNAVAILABLE';

// fleet control with baldur
export const FLEET_CONTROL_REQUEST = 'FLEET_CONTROL_REQUEST';
export const FLEET_CONTROL_AVAILABLE = 'FLEET_CONTROL_AVAILABLE';
export const FLEET_CONTROL_UNAVAILABLE = 'FLEET_CONTROL_UNAVAILABLE';
export const GET_BATCH_LATENCY = 'GET_BATCH_LATENCY';
export const GET_BATCH_LATENCY_SUCCESS = 'GET_BATCH_LATENCY_SUCCESS';
export const GET_BATCH_LATENCY_FAILURE = 'GET_BATCH_LATENCY_FAILURE';

export const GET_JOBS_LATENCIES = 'GET_JOBS_LATENCIES';
export const GET_JOBS_LATENCIES_SUCCESS = 'GET_JOBS_LATENCIES_SUCCESS';
export const GET_JOBS_LATENCIES_FAILURE = 'GET_JOBS_LATENCIES_FAILURE';

export const BALDUR_REQUEST = 'BALDUR_REQUEST';
export const BALDUR_AVAILABLE = 'BALDUR_AVAILABLE';
export const BALDUR_UNAVAILABLE = 'BALDUR_UNAVAILABLE';

// Alerts Widget
export const GET_HISTORIC_ALERTS = 'GET_HISTORIC_ALERTS';
export const GET_HISTORIC_ALERTS_SUCCESS = 'GET_HISTORIC_ALERTS_SUCCESS';
export const GET_HISTORIC_ALERTS_FAILURE = 'GET_HISTORIC_ALERTS_FAILURE';
export const GET_ACTIVE_ALERTS = 'GET_ACTIVE_ALERTS';
export const GET_ACTIVE_ALERTS_SUCCESS = 'GET_ACTIVE_ALERTS_SUCCESS';
export const GET_ACTIVE_ALERTS_FAILURE = 'GET_ACTIVE_ALERTS_FAILURE';

// Hardware Alerts Widget
export const HARDWARE_ALERTS_REQUEST = 'HARDWARE_ALERTS_REQUEST';
export const HARDWARE_ALERTS_AVAILABLE = 'HARDWARE_ALERTS_AVAILABLE';
export const HARDWARE_ALERTS_UNAVAILABLE = 'HARDWARE_ALERTS_UNAVAILABLE';

// Hardware Components Widget
export const GET_HARDWARE_COMPONENTS = 'GET_HARDWARE_COMPONENTS';
export const GET_HARDWARE_COMPONENTS_SUCCESS =
  'GET_HARDWARE_COMPONENTS_SUCCESS';
export const GET_HARDWARE_COMPONENTS_FAILURE =
  'GET_HARDWARE_COMPONENTS_FAILURE';

// Actions Widget
export const ACTION_WIDGET_REQUEST = 'ACTION_WIDGET_REQUEST';
export const ACTION_WIDGET_AVAILABLE = 'ACTION_WIDGET_AVAILABLE';
export const ACTION_WIDGET_UNAVAILABLE = 'ACTION_WIDGET_UNAVAILABLE';

export const ROBOT_MONITORING_REQUEST = 'ROBOT_MONITORING_REQUEST';
export const ROBOT_MONITORING_AVAILABLE = 'ROBOT_MONITORING_AVAILABLE';
export const ROBOT_MONITORING_UNAVAILABLE = 'ROBOT_MONITORING_UNAVAILABLE';

export const TRANSLATIONS_AVAILABLE = 'TRANSLATIONS_AVAILABLE';
export const TRANSLATIONS_UNAVAILABLE = 'TRANSLATIONS_UNAVAILABLE';

export const ROBOT_SERVICING_REQUEST = 'ROBOT_SERVICING_REQUEST';
export const ROBOT_SERVICING_AVAILABLE = 'ROBOT_SERVICING_AVAILABLE';
export const ROBOT_SERVICING_UNAVAILABLE = 'ROBOT_SERVICING_UNAVAILABLE';

export const GET_DEFAULT_HARDWARE_COMPONENTS =
  'GET_DEFAULT_HARDWARE_COMPONENTS';
export const GET_DEFAULT_HARDWARE_COMPONENTS_SUCCESS =
  'GET_DEFAULT_HARDWARE_COMPONENTS_SUCCESS';
export const GET_DEFAULT_HARDWARE_COMPONENTS_FAILURE =
  'GET_DEFAULT_HARDWARE_COMPONENTS_FAILURE';

//  Servicing History Widget
export const GET_SERVICING_HISTORY = 'GET_SERVICING_HISTORY';
export const GET_SERVICING_HISTORY_SUCCESS = 'GET_SERVICING_HISTORY_SUCCESS';
export const GET_SERVICING_HISTORY_FAILURE = 'GET_SERVICING_HISTORY_FAILURE';
export const GET_CHANGE_MOTIVES = 'GET_CHANGE_MOTIVES';
export const GET_CHANGE_MOTIVES_SUCCESS = 'GET_CHANGE_MOTIVES_SUCCESS';
export const GET_CHANGE_MOTIVES_FAILURE = 'GET_CHANGE_MOTIVES_FAILURE';
export const PATCH_CHANGE_REASONS = 'PATCH_CHANGE_REASONS';
export const PATCH_CHANGE_REASONS_SUCCESS = 'PATCH_CHANGE_REASONS_SUCCESS';
export const PATCH_CHANGE_REASONS_FAILURE = 'PATCH_CHANGE_REASONS_FAILURE';

// Groups
export const GROUPS_REQUEST = 'GROUPS_REQUEST';
export const GROUPS_AVAILABLE = 'GROUPS_AVAILABLE';
export const GROUPS_UNAVAILABLE = 'GROUPS_UNAVAILABLE';

// Alerts
export const ALERTS_REQUEST = 'ALERTS_REQUEST';
export const ALERTS_AVAILABLE = 'ALERTS_AVAILABLE';
export const ALERTS_UNAVAILABLE = 'ALERTS_UNAVAILABLE';

// camera Widget
export const CAMERA_WIDGET_REQUEST = 'CAMERA_WIDGET_REQUEST';
export const CAMERA_WIDGET_AVAILABLE = 'CAMERA_WIDGET_AVAILABLE';
export const CAMERA_WIDGET_UNAVAILABLE = 'CAMERA_WIDGET_UNAVAILABLE';

// Severities
export const SEVERITIES_REQUEST = 'SEVERITIES_REQUEST';
export const SEVERITIES_AVAILABLE = 'SEVERITIES_AVAILABLE';
export const SEVERITIES_UNAVAILABLE = 'SEVERITIES_UNAVAILABLE';

// Cloud Logging Interface
export const CLI_REQUEST = 'CLI_REQUEST';
export const CLI_AVAILABLE = 'CLI_AVAILABLE';
export const CLI_UNAVAILABLE = 'CLI_UNAVAILABLE';
// Chart Data
export const DATA_CHART_REQUEST = 'DATA_CHART_REQUEST';
export const DATA_CHART_AVAILABLE = 'DATA_CHART_AVAILABLE';
export const DATA_CHART_UNAVAILABLE = 'DATA_CHART_UNAVAILABLE';

// Cloud Monitoring Interface
export const CMI_REQUEST = 'CMI_REQUEST';
export const CMI_AVAILABLE = 'CMI_AVAILABLE';
export const CMI_UNAVAILABLE = 'CMI_UNAVAILABLE';

// Pipeline Monitoring
export const PIPELINE_MONITORING_REQUEST = 'PIPELINE_MONITORING_REQUEST';
export const PIPELINE_MONITORING_AVAILABLE = 'PIPELINE_MONITORING_AVAILABLE';
export const PIPELINE_MONITORING_UNAVAILABLE =
  'PIPELINE_MONITORING_UNAVAILABLE';

// Robot Status Configuration
export const GET_ROBOTS_STATUS = 'GET_ROBOTS_STATUS';
export const GET_ROBOTS_STATUS_SUCCESS = 'GET_ROBOTS_STATUS_SUCCESS';
export const GET_ROBOTS_STATUS_FAILURE = 'GET_ROBOTS_STATUS_FAILURE';

export const SESSION_REQUEST = 'SESSION_REQUEST';
export const SESSION_AVAILABLE = 'SESSION_AVAILABLE';
export const SESSION_UNAVAILABLE = 'SESSION_UNAVAILABLE';

// Events
export const EVENTS_REQUEST = 'EVENTS_REQUEST';
export const EVENTS_AVAILABLE = 'EVENTS_AVAILABLE';
export const EVENTS_UNAVAILABLE = 'EVENTS_UNAVAILABLE';

// Timeline Trace
export const TIMELINE_TRACE_REQUEST = 'TIMELINE_TRACE_REQUEST';
export const TIMELINE_TRACE_AVAILABLE = 'TIMELINE_TRACE_AVAILABLE';
export const TIMELINE_TRACE_UNAVAILABLE = 'TIMELINE_TRACE_UNAVAILABLE';

// Dashboard Metrics
export const METRICS_REQUEST = 'METRICS_REQUEST';
export const METRICS_AVAILABLE = 'METRICS_AVAILABLE';
export const METRICS_UNAVAILABLE = 'METRICS_UNAVAILABLE';

// Layouts
export const LAYOUTS_REQUEST = 'LAYOUTS_REQUEST';
export const LAYOUTS_AVAILABLE = 'LAYOUTS_AVAILABLE';
export const LAYOUTS_UNAVAILABLE = 'LAYOUTS_UNAVAILABLE';

// Robot Status
export const ROBOT_STATUS_REQUEST = 'ROBOT_STATUS_REQUEST';
export const ROBOT_STATUS_AVAILABLE = 'ROBOT_STATUS_AVAILABLE';
export const ROBOT_STATUS_UNAVAILABLE = 'ROBOT_STATUS_UNAVAILABLE';
