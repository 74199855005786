import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  LinearProgress,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultiSelector(props) {
  const {
    required = false,
    error = false,
    id = 'multi-selector',
    isLoading,
    inputVariant,
    inputLabel = 'Select',
    options,
    canSelectAll = true,
    objectName = 'name',
    objectId = 'id',
    inputSelected,
    setInputSelectedOptions,
    isDisabled = false,
    maxLabelLength = 3,
  } = props;

  const [selectedOption, setSelectedOption] = React.useState([]);
  const [isSelectedAll, setIsSelectedAll] = React.useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (inputSelected) {
      setSelectedOption(inputSelected);
    }
  }, [JSON.stringify(inputSelected)]);

  useEffect(() => {
    setInputSelectedOptions(selectedOption);
    return () => {
      setInputSelectedOptions([]);
    }
  }, [selectedOption]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.slice(-1)[0] !== '') {
      setSelectedOption(
        // On autofill we get a the stringified value.
        typeof value === 'string' ? value.split(',') : value
      );
    } else {
      handleSelectAll();
    }
  };

  const handleRenderValue = (maxLabelLength) => (selected) => {
    const previewString = selected
      .slice(0, maxLabelLength)
      // eslint-disable-next-line eqeqeq
      .flatMap((obj) =>
        obj !== undefined
          ? [options.find((option) => option[objectId] === obj)[objectName]]
          : []
      )
      .join(', ');
    return selected.length > maxLabelLength
      ? `${previewString}, ...`
      : previewString;
  };

  const handleSelectAll = () => {
    setSelectedOption(
      isSelectedAll ? [] : options.filter(option => !option.excluded).map((option) => option[objectId])
    );
    setIsSelectedAll(!isSelectedAll);
  };

  return (
    <div>
      <FormControl
        id={`multi-selector-${id}`}
        key={`multi-selector-${id}`}
        variant={inputVariant}
        sx={{ width: '100%' }}
        disabled={isDisabled}
        required={required}
        error={error}
      >
        <InputLabel id={`mutiple-checkbox-label-${id}`}>{inputLabel}</InputLabel>
        <Select
          key={`multi-selector-select-${id}`}
          labelId={`mutiple-checkbox-label-${id}`}
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.87)' }}
          id={`mutiple-checkbox-${id}`}
          multiple
          value={selectedOption}
          onChange={handleChange}
          label={inputLabel}
          renderValue={handleRenderValue(maxLabelLength)}
          MenuProps={MenuProps}
        >
          {canSelectAll && (
            <MenuItem key="all-multi-select" value="">
              <Checkbox checked={isSelectedAll} />
              <ListItemText
                primary={t('overseer_app.general.Select_all', 'Select All')}
              />
            </MenuItem>
          )}
          {options.map((option) => (
            <MenuItem key={option[objectId]} value={option[objectId]} disabled={option.excluded}>
              <Checkbox
                checked={selectedOption.indexOf(option[objectId]) > -1}
              />
              <ListItemText primary={option[objectName]} secondary={option.secondary} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {isLoading && (
        <LinearProgress
          sx={{ width: '100%', mx: 'auto', top: '-5px' }}
          color="secondary"
        />
      )}
    </div>
  );
}
