import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SchedulerViewContext } from '../../../context/SchedulerView';
import MultiSelector from '../../tools/MultiSelector';
import ReasonForm from './ReasonForm';

import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import Icon from '../../tools/Icon';

const STEPS = ['Recurring Session', 'Comments', 'Confirm Cancelation'];
export default function CancelSessionForm(props) {
  const {
    isDisabled,
    openModal,
    setOpenModal,
    dateCellSelected,
    selectedSessions,
    inputSelectedRobots,
    actionReasons,
    isLoadingActionReasons,
    getActionReasons,
    onClose,
    setSnackAlert,
    cancelSessions,
    loadingCancelSessionResponse,
    cancelMessage,
    removeCancelSessionResponse,
  } = props;
  const [selectedRobots, setSelectedRobots] = useState([]);
  const [selectedCancelOption, setSelectedCancelOption] = useState('this_session');
  const [confirmRemoveSession, setConfirmRemoveSession] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [openWarning, setOpenWarning] = useState(false);

  const [endDate, setEndDate] = useState(dateCellSelected?.date ? dayjs(dateCellSelected.date) : dayjs());

  const reasonForm = useRef({});

  const { t } = useTranslation();

  const {
    selectedChain,
  } = useContext(SchedulerViewContext);

  // Hooks
  useEffect(() => {
    getActionReasons({ command: 'cancel_session' });
    setOpenWarning(false);
    reasonForm.current = {};
    return () => {
      getActionReasons({ command: 'cancel_session' });
      setOpenWarning(false);
    }
  }, []);

  useEffect(() => {
    if (dateCellSelected?.date) {
      setEndDate(dayjs(dateCellSelected.date));
    }
  }, [dateCellSelected]);

  useEffect(() => {
    const selectedRobotsAux = selectedSessions.map(session => {
      return {
        ...inputSelectedRobots.find(robot => robot.robot_uuid === session.robot_uuid),
        sessionType: session.type,
        sessionLocalStartTime: dayjs(session.local_start_time, 'HH:mm:ss A').format('HH:mm A'),
      };
    });
    if (selectedRobotsAux.length) setSelectedRobots(selectedRobotsAux);
    if (openModal) handleStep(0)();
  }, [openModal]);

  useEffect(() => {
    if (!loadingCancelSessionResponse && cancelMessage) {
      if (cancelMessage?.message === 'Success, Session cancelled') {
        const snack = {
          open: true,
          message: `${cancelMessage.data?.length} Session(s) successfully cancelled`,
          severity: 'success',
        };
        setSnackAlert(snack);
        onClose({ canReload: true });
        removeCancelSessionResponse();
      }
    }
  }, [loadingCancelSessionResponse, cancelMessage]);

  // Handlers
  const totalSteps = () => {
    return STEPS.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    if (!isLastStep()) {
      handleStep(activeStep + 1)();
    } else if (isDisabled) {
      setOpenModal(false);
    } else {
      handleCompleteForm();
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setOpenModal(false);
      return;
    }
    handleStep(activeStep - 1)()
  };

  const handleStep = (step) => () => {
    setActiveStep(handleDisableNoPeriodic({ step }) ? 1 : step);
  };

  const handleCompleteForm = () => {
    const {
      comment,
      reason,
    } = reasonForm.current;
    const comments = JSON.stringify({ comment, reason });
    let local_end_date;
    if (selectedCancelOption === 'this_session') {
      local_end_date = dateCellSelected.date;
    } else if (selectedCancelOption === 'this_and_following') {
      local_end_date = null;
    } else if (selectedCancelOption === 'all_sessions_until') {
      local_end_date = endDate.format('YYYY-MM-DD');
    }
    if (!reason) {
      setSnackAlert({
        open: true,
        message: 'Please select a reason',
        severity: 'error',
      });
      setActiveStep(1);
      return;
    } else {
      const cancelForm = selectedSessions.map(session => {
        return local_end_date ? {
          session_id: session.session_id,
          local_start_date: dateCellSelected.date,
          local_end_date,
          comments
        } : {
          session_id: session.session_id,
          local_start_date: dateCellSelected.date,
          comments,
        }
      })
      cancelSessions({ sessions: cancelForm });
    }
  }

  const handleCheckDisabledConfirm = () => {
    return confirmRemoveSession === 'CANCEL' && activeStep === STEPS.length - 1 ? false : !(activeStep !== STEPS.length - 1);
  }

  const handleDisableNoPeriodic = ({ step }) => {
    return step === 0 && selectedSessions.findIndex(session => session.type !== 'Periodic') !== -1;
  }

  const handleSessionTitle = () => {
    if (selectedSessions.length === 1) {
      return dayjs(selectedSessions[0].local_start_time, 'HH:mm:ss A').format('HH:mm A');
    } else {
      return 'Multiple sessions';
    }
  }

  return (
    <>
      <Dialog open={openModal} onClose={() => { setOpenModal(false) }} maxWidth="lg" width='auto'>
        <DialogTitle>
          <Typography variant="subtitle1">Cancel Session {dayjs(dateCellSelected?.date).format('YYYY-MM-DD')} - <b>{handleSessionTitle()}</b></Typography>
        </DialogTitle>
        <DialogContent sx={{ height: `min(100%, calc(${document.getElementById('layout-map-box')?.clientHeight ?? 400}px + 7em))`, overflowY: 'auto', maxHeight: '45em', overflow: 'hidden', pb: 0 }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container columnSpacing={1} sx={{ pt: 1 }}>
                <Grid item xs={3}>
                  {selectedChain?.length > 0 ?
                    <MultiSelector
                      options={selectedChain.map((chain) => ({ name: chain }))}
                      isDisabled={true}
                      inputSelected={selectedChain}
                      setInputSelectedOptions={() => { }}
                      inputLabel={'Chain'}
                      objectName={'name'}
                      objectId={'name'}
                    />
                    : <Box sx={{ height: '2.5em', border: '1px solid #777', borderRadius: '5px', p: 2 }}> <Typography variant="subtitle2">Chain</Typography></Box>
                  }
                </Grid>
                <Grid item xs={4}>
                  {selectedRobots?.length > 0 &&
                    <MultiSelector
                      options={selectedRobots}
                      isDisabled={true}
                      inputSelected={selectedRobots.map(robot => robot.robot_uuid)}
                      setInputSelectedOptions={() => { }}
                      inputLabel={'Robot'}
                      objectName={'robot_code'}
                      objectId={'robot_uuid'}
                    />
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ borderTop: 1, borderColor: 'divider', mt: 2, pt: 1 }}>
                <Stepper nonLinear activeStep={activeStep} sx={{ mb: 2 }}>
                  {STEPS.map((label, index) => (
                    <Step key={label} disabled={handleDisableNoPeriodic({ step: index })} >
                      <StepButton color="inherit" onClick={handleStep(index)}>
                        {label} <br /> {handleDisableNoPeriodic({ step: index }) ? selectedSessions?.length > 1 ? ' (Unique session between selections) ' : ' (This session is not periodic)' : ''}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
                <Box sx={{ mt: 2, mb: 1, py: 1 }}>
                  {/* Cancel recurring session */}
                  <Box sx={activeStep === 0 ? {} : { visibility: 'hidden', width: 0, height: 0 }}>
                    <FormControl>
                      <Typography variant="title"><b>Cancel Recurring Session</b></Typography>
                      <RadioGroup value={selectedCancelOption} onChange={(event) => { setSelectedCancelOption(event.target.value) }} sx={{ ml: 2 }}>
                        <FormControlLabel
                          control={<Radio />}
                          value={'this_session'}
                          label={'This session'}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value={'this_and_following'}
                          label={'This and following sessions'}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value={'all_sessions_until'}
                          label={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Typography sx={{ mr: 1 }}>All sessions until</Typography>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                  <DatePicker
                                    value={endDate}
                                    minDate={dateCellSelected?.date ? dayjs(dateCellSelected.date) : dayjs()}
                                    label={'Finish date'}
                                    onChange={(date) => setEndDate(date)}
                                    disablePast={true}
                                    format="LL"
                                    slotProps={{
                                      textField: {
                                        helperText: 'All scheduling must be in the store local date and time',
                                      },
                                    }}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </Box>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  {/* Reasons for cancel */}
                  <Box sx={activeStep === 1 ? { my: 2 } : { visibility: 'hidden', width: 0, height: 0, top: 0, position: 'absolute' }}>
                    <ReasonForm
                      isDisabled={isDisabled}
                      modalForm={reasonForm}
                      actionReasons={actionReasons}
                      isLoadingActionReasons={isLoadingActionReasons}
                    />
                  </Box>
                  <Box sx={activeStep === 2 ? { my: 0 } : { visibility: 'hidden', width: 0, height: 0 }}>
                    {selectedRobots?.length > 1 ?
                      <Box sx={(theme) => { return { border: `1px solid ${theme.palette['warning']?.main}55`, borderRadius: '5px', padding: '1em', backgroundColor: `${theme.palette['warning']?.main}11` } }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                          <Icon
                            iconName={'warning'}
                            color={'warning'}
                          />
                          <Typography variant="caption" sx={{ fontSize: 'small', ml: 1, color: '#777' }}>You are about to cancel all sessions for <b>{selectedRobots.length}</b> selected robots. This action can not be undone, are you sure you want to continue?</Typography>
                          <Tooltip title="Show robots">
                            <IconButton onClick={() => { setOpenWarning(!openWarning) }} rotate={openWarning ? 180 : 0} sx={{ transition: 'transform 0.3s', transform: openWarning ? 'rotate(180deg)' : 'rotate(0deg)' }}>
                              <IconComponent
                                iconName={'chevron-down-outline'}
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                        <Collapse in={openWarning}>
                          <List sx={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '10em', ml: 4 }}>
                            {
                              selectedRobots.map((robot, index) => (
                                <>
                                  <ListItem key={`warning-robot-list-${index}`} sx={(theme) => ({ display: 'flex', alignItems: 'center', py: 1, mt: 1 })}>
                                    <ListItemAvatar>
                                      <Icon iconName={'information-circle'} color={'gray'} />
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={`${robot.robot_code} - ${robot.store_name} - Floor: ${robot.floor}`}
                                      secondary={`Session type: ${robot.sessionType} - Start time: ${robot.sessionLocalStartTime}`}
                                    />
                                  </ListItem>
                                  <Divider />
                                </>
                              ))
                            }
                          </List>
                        </Collapse>
                      </Box>
                      :
                      <Typography variant="subtitle1" sx={{ maxWidth: '30em' }}>This action can not be undone. Are you sure you want to cancel this session?</Typography>
                    }
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <TextField
                        id="cancel-confirmation-textarea"
                        required
                        error={confirmRemoveSession !== 'CANCEL' && confirmRemoveSession !== ''}
                        label="To confirm this, type: CANCEL"
                        placeholder="CANCEL"
                        disabled={isDisabled}
                        multiline
                        value={confirmRemoveSession}
                        onChange={(event) => { setConfirmRemoveSession(event.target.value); }}
                        sx={{ width: '100%', minHeight: '5em' }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Divider sx={{ with: '80%!important', mb: 1 }} />
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                variant='outlined'
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                {activeStep === 0 ? 'Cancel' : 'Back'}
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button
                color={activeStep === STEPS.length - 1 ? 'secondary' : 'primary'}
                disabled={handleCheckDisabledConfirm()}
                variant='contained'
                onClick={handleNext}
              >{
                  loadingCancelSessionResponse ? <CircularProgress size={20} color='primary' /> :
                    activeStep === STEPS.length - 1 ? isDisabled ? 'Close' : 'Confirm'
                      : 'Next'
                }</Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog >
    </>

  );
}
