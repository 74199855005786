import { Badge, Box, ButtonBase, Grid, IconButton, Skeleton, Tooltip, Typography, useTheme } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconComponent } from "@zippeditoolsjs/zippedi-icons";
import { isEmptyOrUndefined } from "core/utils/validators";
import dayjs from "dayjs";
import { checkViewOnlyPermision } from "../pipelineMonitoring/Utils";
import MenuMultiSessionTable from "./MenuMultiSessionTable";
import MenuSessionTable from "./MenuSessionTable";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { SchedulerViewContext } from '../../context/SchedulerView';
import CancelSessionForm from '../../redux/containers/fleetManagement/CancelSessionForm';
import SessionForm from '../../redux/containers/fleetManagement/SessionForm';
import NotFound from '../notFound/NotFound';
import SvgIcon from '../utils/SVGIcon';
import { DIALOG_TYPES, handleIsCanceled } from './Utils';

const NO_EDIT_SESSION_HOURS_BEFORE = 2;

const blueCircle = {
  display: "inline-block",
  width: "30px",
  height: "30px",
  borderRadius: "50%",
  backgroundColor: "#1E76E8",
  color: "white",
  textAlign: "center",
  lineHeight: "30px",
};

function determineOrdinalPosition(number) {
  const cases = {
    1: number + "st",
    2: number + "nd",
    3: number + "rd",
  };
  var lastDigit = number % 10;
  var secondLastDigit = Math.floor(number / 10) % 10;

  if (secondLastDigit === 1) {
    return number + "th";
  }

  if (cases[lastDigit]) {
    return cases[number];
  } else {
    return number + "th";
  }
}

const columns = [
  { id: "robot_code", label: "Robot", minWidth: 0 },
  { id: "store", label: "Store", minWidth: 170 },
  { id: "store_name", label: "Store Name", minWidth: 170 },
];

export default function SchedulerTable(props) {
  const {
    currentDay,
    currentWeek,
    currentMonth,
    currentYear,
    selectedDate,
    isLoadingSessionsPost,
    newSessionsResponse,
    selectedRobots,
    navOptions,
    viewId,
    dataRobots,
    handleGetSessionsScheduler,
    postSessionUpdates,
    updateSessionResponse,
    deleteSessions,
    deleteSessionsResponse,
    isLoadingDeleteSessions,
    isLocalTime,
    isLoadingRobotSessions,
    handlePreviousWeek,
    handleNextWeek,
    sessionsState,
    isLoadingLayout,
    reload,
    canViewCancelledSessions,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  //table
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  //to access to the data of the table
  const [dateCellSelected, setDateCellSelected] = useState({
    date: "",
    day: "",
  });
  const [cellSelected, setCellSelected] = useState(null);
  const [sessionSelected, setSessionSelected] = useState(null);
  const [actualRowClicked, setActualRowClicked] = useState(null);
  const [actualSessionClicked, setActualSessionClicked] = useState(null);
  //To open menus from table
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSession, setAnchorElSession] = useState(null);
  //dialogs
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openNewSession, setOpenNewSession] = useState(false);
  const [openDeleteSession, setOpenDeleteSession] = useState(false);
  const [openAddAisles, setOpenAddAisles] = useState(false);
  const [openSessionForm, setOpenSessionForm] = useState(false);

  //data
  const [selectedSessions, setSelectedSessions] = useState([]);
  const [schedulerTimeMode, setSchedulerTimeMode] = useState(isLocalTime ? 'user_scheduler' : 'local_scheduler');
  const [sessionsPositions, setSessionsPositions] = useState([]);

  const {
    dialogType,
    setDialogType,
  } = useContext(SchedulerViewContext);

  //To open menus from table
  const openSession = Boolean(anchorElSession);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (newSessionsResponse) {
      if (
        newSessionsResponse.message &&
        newSessionsResponse.message === "Success"
      ) {
      }
    }
  }, [newSessionsResponse]);

  useEffect(() => {
    if (updateSessionResponse) {
      if (
        updateSessionResponse.message &&
        updateSessionResponse.message === "Success"
      ) {
      }
    }
  }, [updateSessionResponse]);

  useEffect(() => {
    if (deleteSessionsResponse) {
      if (
        deleteSessionsResponse.message &&
        deleteSessionsResponse.message === "Success"
      ) {
        handleCloseDeleteSession();
      }
    }
  }, [deleteSessionsResponse]);

  useEffect(() => {
    setSchedulerTimeMode(isLocalTime ? 'user_scheduler' : 'local_scheduler');
  }, [isLocalTime]);

  useEffect(() => {
    if (dataRobots.length > 0 && schedulerTimeMode && currentWeek !== undefined) {
      const newSessionPosition = handleGetSessionPosition();
      setSessionsPositions(newSessionPosition);
    }
  }, [dataRobots, schedulerTimeMode, currentWeek]);

  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const handleClickOpenNewSession = (type) => {
    setOpenSessionForm(true);
    setDialogType(type);
  };

  const handleCloseNewSession = ({ canReload }) => {
    if (canReload) {
      reload();
    }
  };

  const handleUpdateSessions = async (
    session_ids,
    oh_mode,
    local_start_time,
    local_start_date,
    aisles,
    week_days
  ) => {
    await postSessionUpdates(
      session_ids,
      oh_mode,
      local_start_time,
      local_start_date,
      aisles,
      week_days
    );
    handleGetSessionsScheduler();
  };

  const handleClickOpenDeleteSession = (type) => {
    setOpenDeleteSession(true);
    setDialogType(type);
  };

  const handleCloseDeleteSession = () => {
    setOpenDeleteSession(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickCell = (event, index, column, row = null) => {
    setCellSelected(index);
    let day = column.day_of_month;
    let month = selectedDate.$M;
    let year = selectedDate.$y;
    const date = dayjs(new Date(year, month, day)).format("YYYY-MM-DD");
    setDateCellSelected({ date: date, day: column.day_of_week, index: index });
  };

  const handleClickSessions = (event, indexSession, dayNumber, dayOfMonth, date) => {
    if (!checkViewOnlyPermision(navOptions, viewId)) setAnchorEl(event.currentTarget);

    let sessionsInfo = [];
    dataRobots.forEach((element) => {
      const session = element[schedulerTimeMode][currentWeek][dayNumber].sessions[indexSession];
      if (session?.session_id) {
        sessionsInfo.push({ ...session, isEditable: handleSessionIsEditable(dayOfMonth, date, session.user_start_time) });
      }
    });

    setSelectedSessions(sessionsInfo);
  };

  const handleClickSession = (event, index, session, row = null, isEditable = true) => {
    if (!checkViewOnlyPermision(navOptions, viewId)) {
      setAnchorElSession(event.currentTarget);
      setSessionSelected({ ...session, isEditable });
      setSelectedSessions([{ ...session, isEditable }]);
      setActualRowClicked(row);
      setActualSessionClicked(session);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElSession(null);
  };

  const badgeTooltipContent = (session) => {
    const { oh_mode } = session;
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>{t('overseer_app.general.Details', 'Details')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle2'>{`${t('overseer_app.scheduler.OH_Mode', 'OH Mode')}: ${oh_mode}`}</Typography>
        </Grid>
      </Grid>
    );
  };

  const handleSessionHoursDifference = (date, userStartTime) => {
    if (userStartTime) {
      const selectedDateTime = dayjs(`${date} ${userStartTime}`, 'YYYY-MM-DD hh:mm:ss A');
      const currentTime = dayjs();
      const differenceInHours = selectedDateTime.diff(currentTime, 'hour');
      const differenceInMinutes = selectedDateTime.diff(currentTime, 'minute');
      return differenceInHours + differenceInMinutes / 60;
    }
  }

  const handleSessionIsEditable = (dayOfMonth, date = null, userStartTime = null) => {
    if (userStartTime) {
      return !isLocalTime && handleSessionHoursDifference(date, userStartTime) > NO_EDIT_SESSION_HOURS_BEFORE;
    } else {
      return !isLocalTime && ((dayOfMonth >= currentDay &&
        selectedDate.$M + 1 === currentMonth &&
        selectedDate.$y === currentYear) ||
        (selectedDate.$M + 1 > currentMonth && selectedDate.$y === currentYear) ||
        selectedDate.$y > currentYear);
    }
  }

  const isCriticalTimeRange = (date, userStartTime) => {
    const timeDiff = handleSessionHoursDifference(date, userStartTime);
    return 0 <= timeDiff && timeDiff <= NO_EDIT_SESSION_HOURS_BEFORE;
  }

  const handleIsOngoingSession = (session_id, date, local_start_time) => {
    const localID = `${session_id}-${date} ${local_start_time}`;
    return sessionsState?.[localID]?.nav_status === 'navigating';
  }

  const handleGetSessionPosition = () => {
    const dayWeeks = {}
    dataRobots?.forEach((robot) => {
      robot[schedulerTimeMode][currentWeek].forEach((day, index) => {
        const dayWeekDate = day.date ?? index;
        if (dayWeeks[dayWeekDate]) {
          dayWeeks[dayWeekDate].sessions = [...Array(Math.max(day?.sessions?.length ?? 0, dayWeeks[dayWeekDate]?.sessions?.length ?? 0)).keys()];
        } else {
          const dayWeekDate = day.date ?? index;
          dayWeeks[dayWeekDate] = {
            index,
            date: dayWeekDate,
            day_of_month: day.day_of_month,
            day_of_week: day.day_of_week,
            sessions: day.sessions,
          };
        }
      });
    });
    const sessionPositions = Object.values(dayWeeks).sort((a, b) => a.index - b.index);

    return sessionPositions;
  }

  return (
    <>{
      (!selectedRobots?.length || !dataRobots?.length) ? (
        <NotFound
          sx={{ pt: 3 }}
          title={'Make a selection to see content'}
          content={'Select Chain and Robot to see scheduled sessions'}
        />
      ) : isLoadingRobotSessions ?
        <Skeleton variant="rectangular" width="100%" height="11em" />
        :
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: 600, maxWidth: document.getElementsByTagName('main')[0].clientWidth }}>
            <Table sx={{ minWidth: '95em' }}>
              {/* Table headers*/}
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    {
                      "Current week: " + (currentWeek + 1) // The week is 0 based
                    }
                  </TableCell>
                  {/* Days of the week */}
                  {dataRobots[0][schedulerTimeMode][currentWeek].map((day, index) => {
                    // If the day is the current day, it will be highlighted in blue
                    const isToday = !isEmptyOrUndefined(day, 'dict') && day.day_of_month === currentDay && currentMonth === selectedDate.$M + 1; // The month is 0 based
                    const sx = isToday ? { ...blueCircle } : {};
                    return (
                      <TableCell key={`header-cell-${day?.date ?? index}`} align={"center"} colSpan={2}>
                        <Box sx={sx}>
                          {Object.keys(day).length !== 0 ? day.day_of_month : " "}
                        </Box>
                      </TableCell>
                    )
                  })}
                </TableRow>
                <TableRow>
                  <TableCell align="center" colSpan={3}>

                    <Box
                      display={'flex'}
                      justifyContent={'end'}
                      alignItems={'center'}
                    >
                      {/* Icons buttons and Date today */}
                      <IconButton
                        onClick={handlePreviousWeek}
                        disabled={currentWeek === 0}
                        size="small"
                      >
                        <ArrowBackIosIcon />
                      </IconButton>
                      <IconButton
                        onClick={handleNextWeek}
                        disabled={
                          currentWeek ===
                          (dataRobots[0]
                            ? dataRobots[0][schedulerTimeMode].length - 1
                            : 0) || (selectedDate ? false : true)
                        }
                        size="small"
                      >
                        <ArrowForwardIosIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                  {/* Names of the days of the week */}
                  {dataRobots[0][schedulerTimeMode][currentWeek].map((weekDay, index) => (
                    <TableCell
                      key={`Week-cell-${weekDay?.day_of_week ?? index}`}
                      className="ordinal-session-div"
                      align={"center"}
                      colSpan={2}
                      style={{ color: "#FFFFFF", background: "#1a242d", top: 0, position: "relative" }}>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        {Object.keys(weekDay).length !== 0
                          ? weekDay.day_of_week
                          : " "}
                        {/* <Box className="delete-box">
                          <Icon color="secondary" iconName='trash-outline' fontSize="18px" sx={{ p: '5px', mt: '5px' }} />
                        </Box> */}
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  {/* 2nd headers (store, timezone, store name) */}
                  {columns.map((column, index) => (
                    <TableCell
                      key={`header-row-${column.id}`}
                      align={"center"}
                      style={{
                        top: 57,
                        background: "#1a242d",
                        color: "#FFF",
                        width: "1rem",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  {/* Position of the sessions (1st, 2nd, 3rd, etc.) */}
                  {sessionsPositions.map((dayWeek, index) =>
                    Object.keys(dayWeek).length !== 0 ? (
                      <TableCell
                        key={`root-session-cell-${dayWeek?.day_of_month}-${dayWeek?.day_of_week}-${index}`}
                        align={"justify"}
                        m={"auto"}
                        style={{
                          top: 57,
                          textAlign: "center",
                          border: "solid 1px",
                          borderColor: "#1a242d",
                          paddingBlock: 0,
                        }}
                        onClick={(event) => handleClickCell(event, index, dayWeek)}
                        colSpan={2}
                      >
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          {
                            (dayWeek?.sessions?.length ? dayWeek.sessions : dayWeek?.sessions ? [{}] : []).map((session, index_session) => (
                              <div
                                key={`session-div-${dayWeek?.day_of_month}-${dayWeek?.day_of_week}-${index_session}`}
                                style={{
                                  margin: "1rem",
                                  width: "2rem",
                                  display: "flex",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                  paddingBlock: 0,
                                }}
                                onClick={(event) =>
                                  (dayWeek.day_of_month >= currentDay &&
                                    selectedDate.$M + 1 === currentMonth) ||
                                    selectedDate.$M + 1 > currentMonth
                                    ? handleClickSessions(
                                      event,
                                      index_session,
                                      index,
                                      dayWeek.day_of_month,
                                      dayWeek.date
                                    )
                                    : null
                                }
                              >
                                {determineOrdinalPosition(index_session + 1)}
                              </div>
                            ))}
                        </div>
                      </TableCell>
                    ) : (
                      <TableCell
                        key={index}
                        colSpan={2}
                        style={{
                          top: 57,
                          textAlign: "center",
                          border: "solid 1px",
                          borderColor: "#1a242d",
                          paddingBlock: 0,
                        }}
                      >
                        {" - "}
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              {/* Table body */}
              <TableBody>
                {dataRobots
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow tabIndex={-1} key={`Body-row-${row.robot_uuid}`}>
                        {/* Session info */}
                        <TableCell
                          align="center"
                          style={{
                            top: 57,
                            background: "#1a242d",
                            color: "#FFF",
                            width: "1rem",
                          }}
                        >
                          {row.robot_code}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            top: 57,
                            background: "#1a242d",
                            color: "#FFF",
                            width: "1rem",
                          }}
                        >
                          {row.store}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            top: 57,
                            background: "#1a242d",
                            color: "#FFF",
                            width: "1rem",
                          }}
                        >
                          {row.store_name}
                        </TableCell>
                        {/* Sessions */}
                        {row[schedulerTimeMode][currentWeek].map((dayWeek, index) =>
                          <Tooltip
                            key={`session-cell-${index}`}
                            title={dayWeek.session}>
                            <TableCell
                              align={"center"}
                              style={{
                                border: "solid 1px",
                                borderColor: "#1a242d",
                              }}
                              onClick={(event) =>
                                handleClickCell(event, index, dayWeek, row)
                              }
                              colSpan={2}
                              sx={{
                                "&:hover": {
                                  backgroundColor: "#D9D9D9",
                                },
                              }}
                            >
                              <Grid container columnSpacing={1} justifyContent='center'>
                                {dayWeek?.sessions?.length ? dayWeek.sessions?.map((session, index_session) => {
                                  const isEditable = handleSessionIsEditable(dayWeek.day_of_month, dayWeek.date, session.user_start_time);
                                  return (
                                    <Grid item key={`session-Grid-${index_session}`}>
                                      {isEmptyOrUndefined(session, 'dict') || (handleIsCanceled(session.status) && !canViewCancelledSessions) ?
                                        isEditable ?
                                          <IconButton onClick={(event) => handleClickSession(event, index_session, session, row)}
                                            sx={{ backgroundColor: '#1E76E833' }}>
                                            <IconComponent
                                              iconName='add'
                                              color={isEditable ? 'secondary' : null}
                                              style={{ fontSize: '18px', color: '#1E76E8' }}
                                            />
                                          </IconButton>
                                          :
                                          '-'
                                        :
                                        <Badge
                                          badgeContent={
                                            <Tooltip title={badgeTooltipContent(session)}>
                                              <ButtonBase style={{ color: theme.palette.grey['500'], cursor: 'default' }}>
                                                <IconComponent
                                                  iconName='information-circle'
                                                  style={{ fontSize: '18px' }}
                                                />
                                              </ButtonBase>
                                            </Tooltip>
                                          }
                                          invisible={isEmptyOrUndefined(session.oh_mode, 'falsy')}
                                          sx={{ alignItems: 'center', color: isEditable ? theme.palette.info.main : '#777' }}
                                        >
                                          <Box sx={{ mr: 1, ml: 2, borderRight: '1px solid', p: 1 }} >
                                            <Tooltip title={session.type}>
                                              <Typography >
                                                {
                                                  session.type === 'Exception' ?
                                                    <IconComponent iconName='git-branch' style={{ fontSize: '20px' }} />
                                                    : session.type === 'Periodic' ?
                                                      <IconComponent iconName='infinite' style={{ fontSize: '20px' }} />
                                                      : session.type === 'Unique' ?
                                                        <IconComponent iconName='git-commit' style={{ fontSize: '20px' }} />
                                                        : null
                                                }
                                              </Typography>
                                            </Tooltip>
                                            <Tooltip title={session.robot_ack ?
                                              <Typography sx={{ display: 'flex', fontSize: 'small', alignContent: 'center', alignItems: 'center' }}>
                                                <SvgIcon name="overseer-robot-validation" style={{ fontSize: "30px" }} />
                                                Robot acknowledged
                                              </Typography> :
                                              'Robot not acknowledged'}>
                                              <Typography >
                                                {session.robot_ack ?
                                                  <IconComponent iconName='checkmark-circle-outline' style={{ fontSize: '20px' }} />
                                                  :
                                                  <IconComponent iconName='timer-outline' color="gray" style={{ fontSize: '20px' }} />
                                                }
                                              </Typography>
                                            </Tooltip>
                                          </Box>
                                          <Tooltip title={handleIsOngoingSession(session.session_id, dayWeek.date, session.local_start_time) ? 'On going' : isCriticalTimeRange(dayWeek.date, session.user_start_time) ? `You cannot edit a session that is ${NO_EDIT_SESSION_HOURS_BEFORE} hours away from start` : handleIsCanceled(session.status) ? 'Canceled' : ''}>
                                            <ButtonBase
                                              onClick={(event) => handleClickSession(event, index_session, session, row, isEditable)}
                                            >
                                              <Typography
                                                className={handleIsOngoingSession(session.session_id, dayWeek.date, session.local_start_time) ? 'loading-effect' : ''}
                                                sx={{ textDecoration: handleIsCanceled(session.status) ? 'line-through' : 'none' }}
                                                style={{ '--loading-color-light': '#1E76E8' }}
                                              >{session.local_start_time ? dayjs(session.local_start_time, 'HH:mm A').format('HH:mm A') : "-"}{" "}
                                              </Typography>
                                            </ButtonBase>
                                          </Tooltip>
                                        </Badge>
                                      }
                                    </Grid>
                                  )
                                }) : dayWeek?.day_of_month && handleSessionIsEditable(dayWeek.day_of_month)
                                  ? <IconButton
                                    onClick={(event) => handleClickSession(event, 0, null, row)}
                                    sx={{ backgroundColor: '#1E76E833' }}
                                  >
                                    <IconComponent
                                      iconName='add'
                                      style={{ fontSize: '18px', color: '#1E76E8' }}
                                    />
                                  </IconButton>
                                  : '-'
                                }
                              </Grid>
                            </TableCell>
                          </Tooltip>
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={dataRobots.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {/* Open if click all sessions */}
          <MenuMultiSessionTable
            anchorEl={anchorEl}
            open={open}
            dateCellSelected={dateCellSelected}
            selectedDate={selectedDate}
            selectedSessions={selectedSessions}
            handleClickOpenNewSession={handleClickOpenNewSession}
            handleClose={handleClose}
            handleClickOpenDeleteSession={handleClickOpenDeleteSession}
          />
          {/* Open if click only 1 session*/}
          <MenuSessionTable
            anchorEl={anchorElSession}
            open={openSession}
            dateCellSelected={dateCellSelected}
            selectedDate={selectedDate}
            sessionSelected={sessionSelected}
            handleClose={handleClose}
            handleClickOpenNewSession={handleClickOpenNewSession}
            handleClickOpenDeleteSession={handleClickOpenDeleteSession}
          />
          <CancelSessionForm
            openModal={openDeleteSession}
            onClose={handleCloseNewSession}
            inputSelectedRobots={selectedRobots}
            isLoadingLayout={isLoadingLayout}
            actualRowClicked={actualRowClicked}
            dateCellSelected={dateCellSelected}
            setOpenModal={setOpenDeleteSession}
            isDisabled={!sessionSelected?.isEditable}
            handleUpdateSessions={handleUpdateSessions}
            sessionSelected={sessionSelected}
            selectedSessions={selectedSessions}
          />
        </Paper >
    }

      <SessionForm
        openModal={openSessionForm}
        selectedRobots={selectedRobots}
        isLoadingLayout={isLoadingLayout}
        setOpenModal={setOpenSessionForm}
        dateCellSelected={dateCellSelected}
        actualRowClicked={actualRowClicked}
        handleUpdateSessions={handleUpdateSessions}
        isDisabled={!sessionSelected?.isEditable}
        sessionSelected={dialogType === DIALOG_TYPES.ADD ? {} : sessionSelected}
        onClose={handleCloseNewSession}
      />

    </>
  );
}
