import { Divider, MenuItem, Typography } from "@mui/material";
import dayjs from 'dayjs';
import React from "react";
import { useTranslation } from 'react-i18next';
import Icon from '../utils/Icon';
import { DIALOG_TYPES, handleIsCanceled, StyledMenu } from "./Utils";

function MenuSessionTable(props) {
  const {
    anchorEl,
    open,
    handleClose,
    handleClickOpenNewSession,
    handleClickOpenDeleteSession,
    sessionSelected,
  } = props;
  const { t } = useTranslation();

  return (
    <StyledMenu
      anchorEl={anchorEl}
      id="session-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
    >
      {sessionSelected?.isEditable ?
        <>
          {/* Edit Session */}
          {!!sessionSelected.local_start_time && !handleIsCanceled(sessionSelected.status) ? (
            <MenuItem
              onClick={() => handleClickOpenNewSession(DIALOG_TYPES.EDIT)}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Icon color="blue" iconName='create-outline' />
              <Typography>Edit {dayjs(sessionSelected.local_start_time, 'HH:mm A').format('HH:mm A')}</Typography>
            </MenuItem>
          ) : null}
          {/* Add Session */}
          <MenuItem
            onClick={() => handleClickOpenNewSession(DIALOG_TYPES.ADD)}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Icon color="blue" iconName='add-circle-outline' />
            <Typography>{t('overseer_app.fleetManagement.addSession', 'Add session')}</Typography>
          </MenuItem>
          {/* Delete Session */}
          {!!sessionSelected.local_start_time && !handleIsCanceled(sessionSelected.status) ?
            <>
              <Divider style={{ background: "#D8D8D8" }} />
              <MenuItem
                onClick={() => handleClickOpenDeleteSession(DIALOG_TYPES.CANCEL)}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Icon color="secondary" iconName='trash-outline' />
                <Typography sx={{ color: "#FF0000", ml: 1 }}>Cancel {dayjs(sessionSelected.local_start_time, 'HH:mm A').format('HH:mm A')}</Typography>
              </MenuItem>
            </>
            : null}
        </>
        :
        <MenuItem
          onClick={() => handleClickOpenNewSession(DIALOG_TYPES.ONLY_VIEW)}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Icon color="gray" iconName='eye' />
          <Typography>{t('overseer_app.fleetManagement.viewSession', 'View Session')}</Typography>
        </MenuItem>
      }
    </StyledMenu>
  );
}

export default MenuSessionTable;
