import { useEffect, useState } from 'react';
import { Autocomplete, Box, Grid, TextField, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Title } from '@zippeditoolsjs/dashboards';
import { DataGrid } from '@zippeditoolsjs/table';

const { REACT_APP_BASE_URL } = process.env;

const columns = [
  {
    field: 'robot_id',
    headerName: 'Robot id',
    width: 90,
    renderCell: (row) => {
      let robot_code = row.id.split('-');
      return (
        <Link
          href={`${REACT_APP_BASE_URL}/#/robot/${robot_code[0]}/${robot_code[1]}`}
          target="_blank"
        >
          {row.id}
        </Link>
      );
    },
  },
  {
    field: 'store_name',
    headerName: 'Store name',
    width: 120,
  },
  {
    field: 'status_id',
    headerName: 'Status',
    width: 150,
    valueGetter: (params) => params.row.status_id,
  },
  {
    field: 'created_at',
    headerName: 'Status change',
    width: 150,
  },
  {
    field: 'elapsed_time',
    headerName: 'Elapsed time',
    description: 'This column has a value getter and is not sortable.',
    width: 160,
    sortComparator: (a, b) => {
      const timeToHours = (timeStr) => {
        if (timeStr) {
          const dayMatch = timeStr.match(/(\d+)\s*day?/);
          const hourMatch = timeStr.match(/(\d+)\s*hour?/);
          const days = dayMatch ? parseInt(dayMatch[1], 10) : 0;
          const hours = hourMatch ? parseInt(hourMatch[1], 10) : 0;
          return days * 24 + hours;
        } else return 0;
      };
      return timeToHours(a) - timeToHours(b);
    },
  },
  {
    field: 'motive',
    headerName: 'Motive',
    width: 150,
  },
];

export default function StatusTable(props) {
  const { size = 6, statusData } = props;

  const { t } = useTranslation();

  const [filteredData, setFilteredData] = useState([]);
  const [availableStates, setAvailableStates] = useState([]);
  const [selectedStates, setSelectedStates] = useState([
    'Under Maintenance',
    'Non-Operational',
  ]);

  useEffect(() => {
    if (statusData && statusData.length) {
      let statusIds = [...new Set(statusData.map((obj) => obj.status_id))];

      setAvailableStates(statusIds);
    }
  }, [statusData]);

  useEffect(() => {
    if (statusData && statusData.length) {
      let data = statusData.filter((item) =>
        selectedStates.includes(item.status_id)
      );
      setFilteredData(data);
    }
  }, [selectedStates]);

  return (
    <Grid container item xs={size} padding={1}>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          id="tags-states"
          size="small"
          options={availableStates}
          value={selectedStates}
          onChange={(e, values) => setSelectedStates(values)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Robot status"
              placeholder="Status"
            />
          )}
        />
      </Grid>
      {statusData?.length ? (
        <Grid item xs={12}>
          <DataGrid
            getRowId={(row) => row.robot_id}
            columns={columns}
            rows={filteredData}
            sx={{ border: 'none' }}
            disableRowSelectionOnClick
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
          />
        </Grid>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Title>No data</Title>
        </Box>
      )}
    </Grid>
  );
}
