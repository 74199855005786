import DialogTitle from '@mui/material/DialogTitle';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { alpha, styled } from '@mui/material/styles';

export function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export function convertTimeToUtc(date, timeZone) {
  // Extract hours, minutes, and AM/PM from the date string
  const [time, meridiem] = date.split(' ');
  const [hours, minutes] = time.split(':');

  // Get the time zone offset
  const offset = parseInt(timeZone.slice(3)); // extract the number from the string

  // Convert hours to 24-hour format
  let convertedHours = parseInt(hours);
  if (meridiem === 'PM') {
    convertedHours += 12;
  }

  // Apply the time zone offset
  const newHours = convertedHours + offset;

  // Calculate the new hours and minutes
  let newFormattedHours = newHours % 24;
  let newFormattedMinutes = parseInt(minutes);

  // Handle special cases where the result is negative or greater than 24
  if (newFormattedHours < 0) {
    newFormattedHours += 24;
  } else if (newFormattedHours >= 24) {
    newFormattedHours -= 24;
  }

  // Determine if it's AM or PM
  let newMeridiem = 'AM';
  if (newFormattedHours >= 12) {
    newMeridiem = 'PM';
    if (newFormattedHours > 12) {
      newFormattedHours -= 12;
    }
  }

  // Format the new hours and minutes with leading zeros if necessary
  const formattedHours = String(newFormattedHours).padStart(2, '0');
  const formattedMinutes = String(newFormattedMinutes).padStart(2, '0');

  // Construct the final result in "HH:MM AM/PM" format
  const result = `${formattedHours}:${formattedMinutes} ${newMeridiem}`;

  return result;
}

//Input Date must be have format DD/MM/YYYY
export function formatDate(inputDate) {
  var parts = inputDate.split('/');
  var month = parseInt(parts[1], 10);
  var year = parseInt(parts[2], 10);

  var inputDateObj = new Date(year, month - 1, 1);

  // Create a copy of the init date for the following month
  var nextMonthDateObj = new Date(inputDateObj);
  nextMonthDateObj.setMonth(nextMonthDateObj.getMonth() + 1);

  // Get the compponents of the dates
  var dayFormatted = inputDateObj.getDate();
  var monthFormatted = inputDateObj.getMonth() + 1;
  var yearFormatted = inputDateObj.getFullYear();

  var nextMonthDayFormatted = nextMonthDateObj.getDate();
  var nextMonthMonthFormatted = nextMonthDateObj.getMonth() + 1;
  var nextMonthYearFormatted = nextMonthDateObj.getFullYear();

  // Format dates "YYYY-MM-DD"
  var formattedInputDate = `${yearFormatted
    .toString()
    .padStart(2, '0')}-${monthFormatted
      .toString()
      .padStart(2, '0')}-${dayFormatted}`;
  var formattedNextMonthDate = `${nextMonthYearFormatted
    .toString()
    .padStart(2, '0')}-${nextMonthMonthFormatted
      .toString()
      .padStart(2, '0')}-${nextMonthDayFormatted}`;

  return [formattedInputDate, formattedNextMonthDate];
}

// constants
export const DIALOG_TYPES = Object.freeze({
  ADD: 'addSession',
  EDIT: 'editSession',
  CANCEL: 'cancelSession',
  MULTIPLE_ADD: 'addMultiSessions',
  MULTIPLE_EDIT: 'editMultiSessions',
  MULTIPLE_CANCEL: 'multipleCancel',
  ONLY_VIEW: 'onlyViewSession',
});

export const handleIsCanceled = (status) => {
  return status === 'CANCELLED'
}