import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Badge, Box, Button, Grid, IconButton, List, ListItem, Tooltip, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { IconComponent } from "@zippeditoolsjs/zippedi-icons";
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { SchedulerViewContext } from '../../../context/SchedulerView';
import MultiSelector from '../../tools/MultiSelector';
import Icon from '../../utils/Icon';
import { DIALOG_TYPES } from '../Utils';

const WEEK_OPTIONS = [
  { value: 'MONDAY', label: 'Monday', weekNumber: 1 },
  { value: 'TUESDAY', label: 'Tuesday', weekNumber: 2 },
  { value: 'WEDNESDAY', label: 'Wednesday', weekNumber: 3 },
  { value: 'THURSDAY', label: 'Thursday', weekNumber: 4 },
  { value: 'FRIDAY', label: 'Friday', weekNumber: 5 },
  { value: 'SATURDAY', label: 'Saturday', weekNumber: 6 },
  { value: 'SUNDAY', label: 'Sunday', weekNumber: 0 },
];

export default function ScheduleForm(props) {
  const {
    isDisabled,
    scheduleForm,
    inputStartDate = new Date(),
    inputEndDate,
    inputStartTime = dayjs().hour(21).minute(0).second(0).toDate(),
    inputEndTime = dayjs().hour(4).minute(0).second(0).toDate(),
    disablePast = true,
    weekDays = [],
  } = props;

  const [startDate, setStartDate] = useState(dayjs(inputStartDate));
  const [endDate, setEndDate] = useState(inputEndDate ? dayjs(inputEndDate) : null);
  const [startTime, setStartTime] = useState(dayjs(inputStartTime));
  const [endTime, setEndTime] = useState(inputEndTime ? dayjs(inputEndTime) : inputEndTime);
  const [repeatsOn, setRepeatsOn] = useState(null);
  const [openWarning, setOpenWarning] = useState(!!scheduleForm.current?.warnings);

  const {
    dialogType,
  } = useContext(SchedulerViewContext);

  useEffect(() => {
    if (startDate && repeatsOn?.length === 0) {
      setEndDate(startDate);
    }
  }, [startDate, repeatsOn]);

  useEffect(() => {
    let newRepeatsOn = [];
    if (weekDays?.length) {
      newRepeatsOn = weekDays.map((day) => WEEK_OPTIONS.find((option) => option.weekNumber === day)?.weekNumber);
      setRepeatsOn(newRepeatsOn);
    }
  }, [weekDays]);

  useEffect(() => {
    if (repeatsOn !== null) {
      scheduleForm.current = {
        startDate: startDate.format('YYYY-MM-DD'),
        repeatsOn: repeatsOn,
        endDate: endDate ? endDate.format('YYYY-MM-DD') : null,
        startTime: startTime.format('HH:mm'),
        endTime: endTime.format('HH:mm'),
      };
    }
  }, [startDate, repeatsOn, endDate, startTime, endTime]);

  return (
    <>
      <Grid container sx={{ maxWidth: openWarning ? '100%' : '33em', transition: 'all 0.3s ease-in-out' }}>
        {/* FORM */}
        <Grid item container
          xs={openWarning ? 6 : 11}
          columnSpacing={2} rowSpacing={3}
          sx={{
            maxWidth: '33em!important',
            transition: 'all 0.3s ease-in-out', // Ajustar transición para suavidad
          }}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', flowDirection: 'columns', alignItems: 'center' }}>
              <IconComponent iconName='information-circle' style={{ fontSize: "20px", color: "#CCC" }} />
              <Typography variant="subtitle2" sx={{ fontSize: 'small', ml: 1, color: '#777' }}>All scheduling must be in the store local date and time</Typography>
            </Box>
          </Grid>
          {/* Start Date */}
          <Grid item xs={12} sx={{ mt: 0, paddingTop: '0.5em!important' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  disabled={isDisabled || dialogType === DIALOG_TYPES.EDIT}
                  minDate={dayjs()}
                  label={'Start date'}
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                  format="LL"
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          {/* Repeats On */}
          <Grid item xs={12}>
            <Box sx={{ maxWidth: '14.5em' }}>
              <MultiSelector
                isDisabled={isDisabled}
                id='schedule-repeats-on'
                options={WEEK_OPTIONS}
                inputSelected={repeatsOn}
                setInputSelectedOptions={setRepeatsOn}
                inputLabel={repeatsOn?.length ? 'Repeats on' : 'Does not repeat'}
                objectName={'label'}
                objectId={'weekNumber'}
                maxLabelLength={6}
              />
            </Box>
          </Grid>
          {/* Finish Date */}
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  value={endDate}
                  minDate={dayjs().isBefore(startDate) ? dayjs() : startDate}
                  label={'Finish date'}
                  disabled={!repeatsOn?.length | isDisabled}
                  onChange={(date) => setEndDate(date)}
                  disablePast={disablePast}
                  format="LL"
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          {/* Start At */}
          <Grid item xs={12} sm={openWarning ? 12 : 6} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['TimePicker']}>
                <TimePicker
                  disabled={isDisabled}
                  label="Start time"
                  value={startTime}
                  onChange={(date) => setStartTime(date)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          {/* End At */}
          <Grid item xs>
            <Badge
              color="primary"
              badgeContent={
                endTime < startTime ?
                  <Tooltip title="Ending tomorrow at">
                    <span>+1 day</span>
                  </Tooltip>
                  : null
              }
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['TimePicker']}>
                  <TimePicker
                    disabled={isDisabled}
                    label="End time"
                    value={endTime}
                    onChange={(date) => setEndTime(date)}
                    slots={{
                      openPickerButton: ({ onClick, ...buttonProps }) => (
                        <Tooltip title="This time will be ever the time after the start time">
                          <IconButton {...buttonProps} onClick={onClick}>
                            <AccessTimeIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Badge>
          </Grid>
        </Grid>
        {/* WARNINGS */}
        <Grid item xs={openWarning ? 6 : 1} sx={{
          transition: 'all 0.3s ease-in-out',
          overflow: 'hidden',
          opacity: openWarning ? 1 : 0,
          visibility: openWarning ? 'visible' : 'hidden',
        }}>

          <List sx={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '22em', ml: 4 }}>
            {
              scheduleForm.current.warnings && scheduleForm.current.warnings.map((warningMessage, index) => (
                <ListItem key={`warning-grid-${index}`} sx={(theme) => ({ display: 'flex', alignItems: 'center', border: `1px solid ${theme.palette.warning.main}`, py: 1, borderRadius: '5px', mt: 1 })}>
                  <Icon
                    iconName={'warning'}
                    color={'warning'}
                    tooltip={'No data'}
                  />
                  <Typography variant="caption" sx={{ fontSize: 'small', ml: 1, color: '#777' }}>{warningMessage}</Typography>
                </ListItem>
              ))
            }
          </List>
        </Grid>
      </Grid>
      {scheduleForm.current.warnings?.length > 0 &&
        <Box sx={(theme) => { return { display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', color: theme => theme.palette['warning'].main, } }}>
          <Button variant="text" color="primary" onClick={() => setOpenWarning(!openWarning)} sx={{ fontSize: '2em', color: 'inherit', p: '5px', mt: 1 }}>
            <Icon iconName={'warning'} color='warning' tooltip='See warning' />
          </Button>
          <Typography variant="caption" sx={{ fontSize: 'small', color: 'inherit' }}>{scheduleForm.current.warnings?.length} Warnings</Typography>
        </Box>
      }
    </>
  );
}
