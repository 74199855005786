import { Box, Divider, FormControl, FormControlLabel, Grid, InputLabel, LinearProgress, MenuItem, Radio, RadioGroup, Select, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import RobotLayouts from '../../../redux/containers/layouts/RobotLayouts';
import MultiSelector from '../../tools/MultiSelector';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

export default function ScanningForm(props) {
  const {
    isDisabled,
    scanningForm,
    store,
    floor,
    inputOH = 'OFF',
    inputSelectedAisles = [],
    inputSelectedZones = [],
    isLoadingLayout,
  } = props;
  const [ohMode, setOhMode] = useState(inputOH);
  const [selectedLayer, setSelectedLayer] = useState('zones');
  const [selectedAisles, setSelectedAisles] = useState([]);
  const [aisleOptions, setAisleOptions] = useState([]);
  const [selectedZones, setSelectedZones] = useState([]);
  const [zonesOptions, setZonesOptions] = useState([]);

  const { t } = useTranslation();
  const OH_OPTIONS = [
    { value: 'ON', label: 'ON' },
    { value: 'OFF', label: 'OFF' },
    { value: 'PRIORITY', label: 'PRIORITY' },
  ];
  const LAYOUTS_LAYERS = [
    { name: 'zones', label: 'Zones' },
    { name: 'aisles', label: 'Aisles' },]

  // Hooks
  useEffect(() => {
    scanningForm.current = {
      ohMode,
      selectedLayer,
      selectedAisles: selectedLayer === 'aisles' ? selectedAisles.map((aisle) => aisle.id) : [],
      selectedZones: selectedLayer === 'zones' ? selectedZones.map((zone) => zone.id) : [],
    };
  }, [ohMode, selectedLayer, selectedAisles, selectedZones]);

  useEffect(() => {
    if (aisleOptions?.length && inputSelectedAisles?.length && !isLoadingLayout) {
      setTimeout(() => {
        handleSelectedAisles(inputSelectedAisles);
      }, 550);
    } else if (inputSelectedAisles?.length) {
      setSelectedLayer('aisles');
    }
  }, [aisleOptions, isLoadingLayout]);

  useEffect(() => {
    if (zonesOptions?.length && inputSelectedZones?.length && !isLoadingLayout) {
      setTimeout(() => {
        handleSelectedZones(inputSelectedZones);
      }, 550);
    } else if (inputSelectedZones?.length) {
      setSelectedLayer('zones');
    }
  }, [zonesOptions, isLoadingLayout]);


  // Handlers
  const handleOptions = (targetList, setOptions) => {
    let targetSorted = targetList.sort((a, b) => {
      if (a.excluded && !b.excluded) return 1;
      if (!a.excluded && b.excluded) return -1;
      if (a.isInThePlan && !b.isInThePlan) return -1;
      if (!a.isInThePlan && b.isInThePlan) return 1;
      return 0;
    });
    targetSorted = targetSorted.map((aisle) => {
      return {
        ...aisle,
        secondary: aisle.excluded ? 'Aisle excluded by layout' : aisle.client_name ? `Client name: ${aisle.client_name}` : '',
      }
    });
    setOptions(targetSorted);
  }

  const handleChangeSelectScanMode = (event) => {
    setOhMode(event.target.value);
  };

  const handleSelectedAisles = (aisleList) => {
    const aisles = aisleOptions.filter((aisle) => aisleList.includes(aisle.id));
    setSelectedAisles(aisles);
  }

  const handleSelectedZones = (zoneList) => {
    const zones = zonesOptions.filter((zone) => zoneList.includes(zone.id));
    setSelectedZones(zones);
  }

  const handleLayers = (event) => {
    const newLayer = event.target.value;
    setSelectedLayer(newLayer);
  };

  return (
    <Grid container sx={{ height: '100%' }} columnSpacing={8}>
      <Grid item xs={'auto'} sx={{ maxHeight: 'calc(400px - 0.8em)', overflow: 'auto' }}>
        <RobotLayouts
          store={store}
          floor={floor}
          onChangeAisles={(aisles) => handleOptions(aisles, setAisleOptions)}
          onChangeZones={(zones) => handleOptions(zones, setZonesOptions)}
          layersDisplay={[selectedLayer]}
          highlightedBoxes={selectedLayer === 'aisles' ? selectedAisles : selectedZones}
        />
      </Grid>
      <Grid item xs sx={{ mt: 1 }}>
        <Grid container rowSpacing={4}>
          {/* OH mode */}
          <Grid item xs={12}>
            <FormControl sx={{ minWidth: '35%' }}
            >
              <InputLabel id="oh_mode-select-label">{t('overseer_app.scheduler.OH_Mode', 'OH Mode')}</InputLabel>
              <Select
                disabled={isDisabled}
                labelId="oh_mode-select-label"
                id="oh_mode-select-label"
                value={ohMode}
                label="OH Mode"
                onChange={handleChangeSelectScanMode}
              >
                {
                  OH_OPTIONS.map((item) => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          {/* LAYERS */}
          <Grid item xs={12}>
            <FormControl>
              <Typography variant="subtitle2">Scanning Area</Typography>
              <RadioGroup row value={selectedLayer} onChange={handleLayers}>
                {LAYOUTS_LAYERS.map((item) => (
                  <FormControlLabel
                    value={item.name}
                    control={<Radio />}
                    label={item.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          {/* AISLES */}
          <Grid item xs={selectedLayer === 'aisles' ? 6 : 0} sx={{ display: selectedLayer === 'aisles' ? 'block' : 'none' }}>
            {
              isLoadingLayout ?
                <Box sx={{ border: 'solid 1px #CCC', borderRadius: '3px', p: 1 }}>
                  <LinearProgress sx={{ width: '100%' }} />
                </Box>
                :
                !aisleOptions?.[0]?.name ?
                  <Box sx={{ border: 'solid 1px #CCC', borderRadius: '3px', p: 1 }}>
                    <Typography variant="caption" sx={{ fontSize: 'small', color: '#888' }}>No aisles available</Typography>
                  </Box>
                  :
                  <>
                    <MultiSelector
                      required
                      id='scanning-aisles'
                      options={aisleOptions}
                      isDisabled={aisleOptions.length === 0 || isDisabled}
                      inputSelected={selectedAisles.map((aisle) => aisle.name)}
                      setInputSelectedOptions={handleSelectedAisles}
                      inputLabel={'Aisles'}
                      objectName={'name'}
                      objectId={'id'}
                      maxLabelLength={6}
                    />
                    <Typography variant="caption" sx={{ fontSize: 'small', color: '#888' }}>*Only activated aisles in layout available for selection</Typography>
                  </>
            }
          </Grid>
          {/* ZONES */}
          <Grid item xs={selectedLayer === 'zones' ? 6 : 0} sx={{ display: selectedLayer === 'zones' ? 'block' : 'none' }}>
            {
              isLoadingLayout ?
                <Box sx={{ border: 'solid 1px #CCC', borderRadius: '3px', p: 1 }}>
                  <LinearProgress sx={{ width: '100%' }} />
                </Box>
                :
                !zonesOptions?.[0]?.name ?
                  <Box sx={{ border: 'solid 1px #CCC', borderRadius: '3px', p: 1 }}>
                    <Typography variant="caption" sx={{ fontSize: 'small', color: '#888' }}>No zones available</Typography>
                  </Box>
                  :
                  <>
                    <MultiSelector
                      required
                      id='scanning-zones'
                      options={zonesOptions}
                      isDisabled={zonesOptions.length === 0 || isDisabled}
                      inputSelected={selectedZones.map((zone) => zone.name)}
                      setInputSelectedOptions={handleSelectedZones}
                      inputLabel={'Zones'}
                      objectName={'name'}
                      objectId={'id'}
                      maxLabelLength={6}
                    />
                    <Typography variant="caption" sx={{ fontSize: 'small', color: '#888' }}>*Only activated zones in layout available for selection</Typography>
                  </>
            }
          </Grid>
          <Grid item xs={12}>
            {inputSelectedAisles?.length > 0 && selectedLayer !== 'aisles' ?
              <>
                <Divider />
                <Box sx={(theme) => ({ display: 'flex', alignItems: 'center', fontSize: 'small', color: theme.palette.orange.main })}>
                  <IconComponent iconName='warning' style={{ fontSize: "4em", color: "inherit" }} />
                  <Typography variant="caption" sx={(theme) => ({ pl: 2, fontSize: 'small' })}>
                    <b>{inputSelectedAisles?.length}</b> pre-selected aisles note that if you do not select “Aisles” as the scanning area, it will be replaced by the {selectedLayer} you are currently sending</Typography>
                </Box>
              </>
              : inputSelectedZones?.length > 0 && selectedLayer !== 'zones' ?
                <>
                  <Divider />
                  <Box sx={(theme) => ({ display: 'flex', alignItems: 'center', fontSize: 'small', color: theme.palette.orange.main })}>
                    <IconComponent iconName='warning' style={{ fontSize: "4em", color: "inherit" }} />
                    <Typography variant="caption" sx={(theme) => ({ pl: 2, fontSize: 'small' })}>
                      <b>{inputSelectedZones?.length}</b> pre-selected zones note that if you do not select “Zones” as the scanning area, it will be replaced by the {selectedLayer} you are currently sending</Typography>
                  </Box>
                </>
                : null
            }
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
