import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import dayjs from 'dayjs';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SchedulerViewContext } from '../../../context/SchedulerView';
import MultiSelector from '../../tools/MultiSelector';
import ScanningForm from './ScanningForm';
import ScheduleForm from './ScheduleForm';

import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import { DIALOG_TYPES } from '../Utils';
import ReasonForm from './ReasonForm';

export default function SessionForm(props) {
  const {
    isDisabled,
    openModal,
    setOpenModal,
    actualRowClicked = {},
    dateCellSelected,
    sessionSelected,
    selectedRobots,
    isLoadingLayout,
    actionReasons,
    isLoadingActionReasons,
    getActionReasons,
    postNewSessions,
    updateNewSessions,
    removeNewSessionResponse,
    newSessionResponse,
    loadingNewSessions,
    onClose,
    setSnackAlert,
  } = props;

  const {
    selectedChain,
    dialogType,
  } = useContext(SchedulerViewContext);

  const [selectedRobot, setSelectedRobot] = useState([]);
  const [selectedStore, setSelectedStore] = useState('');
  const [selectedFloor, setSelectedFloor] = useState('1');
  const [sessionsSteps, setSessionsSteps] = useState(dialogType !== DIALOG_TYPES.ONLY_VIEW ? ['Schedule', 'Scanning', 'Comments'] : ['Schedule', 'Scanning']);

  const [activeStep, setActiveStep] = useState(0);

  const reasonForm = useRef({});
  const scheduleForm = useRef({});
  const scanningForm = useRef({});

  const { t } = useTranslation();

  // Hooks
  useEffect(() => {
    getActionReasons({ command: 'create_session' });
  }, []);

  useEffect(() => {
    if (dialogType === DIALOG_TYPES.ONLY_VIEW) {
      setSessionsSteps(['Schedule', 'Scanning']);
    } else {
      setSessionsSteps(['Schedule', 'Scanning', 'Comments']);
    }
  }, [dialogType]);

  useEffect(() => {
    const selectedRobotAux = selectedRobots.find(robot => robot.robot_uuid === actualRowClicked?.robot_uuid);
    if (selectedRobotAux) setSelectedRobot([selectedRobotAux]);
    setSelectedStore(selectedRobotAux?.store);
    setSelectedFloor(selectedRobotAux?.floor ?? 1);
    if (openModal) setActiveStep(0);
  }, [openModal]);

  useEffect(() => {
    if (!loadingNewSessions) {
      if (newSessionResponse?.message === 'Success, Session created') {
        const snack = {
          open: true,
          message: `Session successfully created for ${selectedRobot[0].robot_code}`,
          severity: 'success',
        };
        setSnackAlert(snack);
        onClose({ canReload: true });
        removeNewSessionResponse();
        removeWarnings();
      } else if (newSessionResponse?.message === "Success, Session patched") {
        const snack = {
          open: true,
          message: `Session successfully updated for ${selectedRobot[0].robot_code}`,
          severity: 'success',
        };
        setSnackAlert(snack);
        onClose({ canReload: true });
        removeNewSessionResponse();
        removeWarnings();
      } else if (newSessionResponse?.message === "Conflicts found in sessions") {
        const snack = {
          open: true,
          message: 'Another session is already scheduled for this robot at this time, please check the selected dates',
          severity: 'error',
        };
        setSnackAlert(snack);
        removeNewSessionResponse();
        setActiveStep(0);

        const {
          conflicts_list
        } = newSessionResponse.data;

        let warnings = []
        conflicts_list.forEach(conflict => {
          warnings = warnings.concat(conflict.conflicted_sessions.map(conflicted_session => {
            const sessionType = conflicted_session.type;
            const startTime = dayjs(conflicted_session.local_start_time, 'HH:mm').format('hh:mm A');
            const parsedWeekDays = conflicted_session.week_days.map((dayNumber => dayjs().day(dayNumber).format('dddd'))).join(', ');
            let customMessage = '';
            if (sessionType === 'Periodic') {
              customMessage = `For all days ${parsedWeekDays} from ${conflicted_session.local_start_date} to ${conflicted_session.local_end_date}`;
            } else if (sessionType === 'Unique') {
              customMessage = `For this day ${conflicted_session.local_start_date}`;
            }
            return <span>Conflict with <b>{sessionType}</b> session starting at <b>{startTime}</b> and lasting <b>{conflicted_session.max_minutes}</b> minutes. {customMessage}</span>
          }
          )
          )
        });
        scheduleForm.current = {
          ...scheduleForm.current,
          warnings
        }
      }
    }
  }, [loadingNewSessions, newSessionResponse]);


  // Handlers
  const removeWarnings = () => {
    scheduleForm.current = {
      ...scheduleForm.current,
      warnings: '',
    }
  }
  const totalSteps = () => {
    return sessionsSteps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    if (!isLastStep()) {
      setActiveStep(activeStep + 1);
    } else if (isDisabled) {
      setOpenModal(false);
    } else {
      handleCompleteForm();
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setOpenModal(false);
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleCompleteForm = () => {
    const {
      coordinates,
      robot_uuid
    } = selectedRobot[0];
    const {
      repeatsOn: week_days,
      startTime: local_start_time,
      endTime: local_end_time,
      startDate: local_start_date,
      endDate: local_end_date,
    } = scheduleForm.current;

    let max_minutes = dayjs(local_end_time, 'HH:mm').diff(dayjs(local_start_time, 'HH:mm'), 'minutes');
    max_minutes = max_minutes < 0 ? (max_minutes + (24 * 60)) : max_minutes;
    const {
      ohMode: overhead_mode,
      selectedAisles: aisles,
      selectedZones: zones,
    } = scanningForm.current;
    const {
      comment,
      reason,
    } = reasonForm.current;
    const comments = JSON.stringify({ comment, reason });
    const session_id = dialogType === DIALOG_TYPES.EDIT ? sessionSelected.session_id : undefined;
    const periodic_session_id = dialogType === DIALOG_TYPES.EDIT ? sessionSelected.periodic_session_id : undefined;

    // if aisles and zones are empty
    if (aisles.length === 0 && zones.length === 0) {
      setSnackAlert({
        open: true,
        message: 'Please select at least one aisle or zone',
        severity: 'error',
      });
      setActiveStep(1);
      return;
    } else if (!reason) {
      setSnackAlert({
        open: true,
        message: 'Please select a reason',
        severity: 'error',
      });
      setActiveStep(2);
      return;
    } else {
      const sessions = {
        sessions: [{
          coordinates,
          local_start_date,
          local_start_time,
          robot_uuid,
          zones,
          aisles,
          comments,
          week_days,
          max_minutes,
          overhead_mode,
          local_end_date,
          session_id,
          periodic_session_id,
          // TODO: Pending check the order by priority
          // priority_aisles
        }]
      }

      dialogType === DIALOG_TYPES.EDIT ? updateNewSessions(sessions) : [DIALOG_TYPES.ADD, DIALOG_TYPES.MULTIPLE_ADD].includes(dialogType) && postNewSessions(sessions);
    }
  }

  return (
    <>
      <Dialog open={openModal} onClose={() => { setOpenModal(false) }} maxWidth="lg" width='auto'>
        <DialogTitle>
          <Typography variant="subtitle1">
            {
              dialogType === DIALOG_TYPES.ADD ? t('overseer_app.scheduler.add_session', 'Add Session') :
                dialogType === DIALOG_TYPES.EDIT ? t('overseer_app.scheduler.edit_session', 'Edit Session') :
                  dialogType === DIALOG_TYPES.MULTIPLE_ADD ? t('overseer_app.scheduler.add_multi_sessions', 'Add Multi Sessions') :
                    dialogType === DIALOG_TYPES.ONLY_VIEW ? t('overseer_app.scheduler.view_session', 'Only View') :
                      ''}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ height: `min(100%, calc(${document.getElementById('layout-map-box')?.clientHeight ?? 400}px + 7em))`, overflowY: 'auto', maxHeight: '45em', overflow: 'hidden', pb: 0 }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container columnSpacing={1} sx={{ pt: 1 }}>
                <Grid item xs={3}>
                  {selectedChain?.length > 0 ?
                    <MultiSelector
                      options={selectedChain.map((chain) => ({ name: chain }))}
                      isDisabled={true}
                      inputSelected={selectedChain}
                      setInputSelectedOptions={() => { }}
                      inputLabel={'Chain'}
                      objectName={'name'}
                      objectId={'name'}
                    />
                    : <Box sx={{ height: '2.5em', border: '1px solid #777', borderRadius: '5px', p: 2 }}> <Typography variant="subtitle2">Chain</Typography></Box>
                  }
                </Grid>
                <Grid item xs={4}>
                  {selectedRobot?.length > 0 &&
                    <MultiSelector
                      options={selectedRobot}
                      isDisabled={true}
                      inputSelected={selectedRobot.map(robot => robot.robot_uuid)}
                      setInputSelectedOptions={() => { }}
                      inputLabel={'Robot'}
                      objectName={'robot_code'}
                      objectId={'robot_uuid'}
                    />
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ borderTop: 1, borderColor: 'divider', mt: 2, pt: 1 }}>
                <Stepper nonLinear activeStep={activeStep} sx={{ mb: 2 }}>
                  {sessionsSteps.map((label, index) => (
                    <Step key={label} >
                      <StepButton color="inherit" onClick={handleStep(index)}>
                        {label}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
                <Box sx={{ mt: 2, mb: 1, py: 1 }}>
                  <Box sx={activeStep === 0 ? {} : { visibility: 'hidden', width: 0, height: 0, opacity: 0 }}>
                    <ScheduleForm
                      isDisabled={isDisabled}
                      scheduleForm={scheduleForm}
                      inputStartDate={dateCellSelected.date}
                      inputEndDate={sessionSelected?.local_end_date}
                      inputStartTime={sessionSelected?.local_start_time ? dayjs(sessionSelected.local_start_time, "hh:mm A") : undefined}
                      inputEndTime={sessionSelected?.local_start_time && sessionSelected.max_minutes ? dayjs(sessionSelected.local_start_time, "hh:mm A").add(sessionSelected.max_minutes, 'minutes') : undefined}
                      weekDays={sessionSelected?.week_days}
                    />
                  </Box>
                  <Box sx={activeStep === 1 ? { my: 2 } : { visibility: 'hidden', width: 0, height: 0 }}>
                    <ScanningForm
                      isDisabled={isDisabled}
                      scanningForm={scanningForm}
                      store={selectedStore}
                      floor={selectedFloor}
                      isLoadingLayout={isLoadingLayout}
                      inputSelectedAisles={sessionSelected?.aisles}
                      inputSelectedZones={sessionSelected?.zones}
                      inputOH={sessionSelected?.overhead_mode}
                    />
                  </Box>
                  <Box sx={activeStep === 2 ? { py: 2 } : { visibility: 'hidden', width: 0, height: 0 }}>
                    <ReasonForm
                      isDisabled={isDisabled}
                      modalForm={reasonForm}
                      actionReasons={actionReasons}
                      isLoadingActionReasons={isLoadingActionReasons}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Divider sx={{ with: '80%!important', mb: 1 }} />
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                variant='outlined'
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                {activeStep === 0 ? 'Cancel' : 'Back'}
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button variant='contained' onClick={handleNext}>{
                loadingNewSessions ? <CircularProgress size={20} color='secondary' /> :
                  activeStep === sessionsSteps.length - 1 ? isDisabled ? 'Close' : 'Finish'
                    : 'Next'
              }</Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog >
    </>

  );
}
